<template>
	<v-dialog :max-width="$vuetify.breakpoint.mdAndUp ? 800 : 535" v-model="modal" :persistent="true">
		<v-card height="30" color="black" class="top-bar">
			<v-icon color="white" class="close-icon bungee" @click="$emit('close')">{{ closeIcon }}</v-icon>
		</v-card>
		<v-card v-if="promoWeek.loaded" color="black" max-height="95vh" class="overflow-y-scroll pa-0">
			<div class="campaign-body mt-0">
				<div class="content-section">
					<div class="d-flex justify-center justify-md-start mt-0">
						<img
							v-if="$vuetify.breakpoint.mdAndUp"
							src="/banners/obsbot/streamStickers_logo-lg.png"
							width="188"
							height="114"
							class="mt-9 stream-logo"
							alt="Stream Stickers"
							srcset="" />
						<img
							v-if="$vuetify.breakpoint.smAndDown"
							src="/banners/obsbot/Mobile streamStickers_logo.png"
							class="mt-12 stream-logo"
							alt="Stream Stickers"
							srcset="" />
						<img src="/banners/obsbot/plus.png" alt="" class="mt-12 mt-md-15 mx-0 plus" srcset="" />
						<img
							v-if="$vuetify.breakpoint.mdAndUp"
							src="/banners/obsbot/OBSBOT logo-lg.png"
							width="279"
							height="117"
							class="obs-bot mt-5 mt-md-4"
							alt="OBSBOT"
							srcset="" />
						<img
							v-if="$vuetify.breakpoint.smAndDown"
							src="/banners/obsbot/Mobile OBSBOT Logo.png"
							class="obs-bot mt-12 mt-md-12"
							alt="OBSBOT"
							srcset="" />
						<img
							v-if="$vuetify.breakpoint.smAndDown"
							src="/banners/obsbot/Mobile 3x Camera Giveaway.png"
							class="mobile-giveaway mt-13"
							alt=""
							srcset="" />
					</div>

					<!-- <template v-if="$vuetify.breakpoint.smAndDown">
						<div class="text-center">
							<img src="/banners/obsbot/Mobile 3x Camera Giveaway.png" class="mobile-giveaway" alt="" srcset="" />
						</div>
					</template> -->

					<v-row v-if="$vuetify.breakpoint.smAndDown" no-gutters justify="center">
						<v-col cols="12" md="8" sm="12">
							<img
								v-if="showPlayArrow"
								class="playImg"
								src="/banners/obsbot/VideoPlay.png"
								width="100%"
								alt="Video Play"
								@click="actions.toggleVideo" />
							<div class="video-area">
								<video
									v-show="!showPlayArrow"
									ref="video"
									:width="$vuetify.breakpoint.mdAndUp ? 480 : '100%'"
									@timeupdate="actions.onTimeUpdate"
									@ended="actions.onVideoEnded">
									<source :src="promoWeek.video" type="video/mp4" />
									Your browser does not support HTML video.
								</video>
								<div v-if="!showPlayArrow" class="seekbar">
									<div class="progress-bar">
										<div class="progress" :style="{ width: progress + '%' }"></div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col cols="6">
							<div class="camera text-center">
								<img :src="promoWeek.image" alt="" />
							</div>
							<p class="bungee text-center mb-0">
								<strong class="">Week</strong>
								<strong class="pink--text fs--25 mx-2">{{ promoWeek.week }}</strong>
								<strong class="">OF</strong>
								<strong class="text-green fs--20 mx-2">{{ promoWeek.totalWeeks }}</strong>
							</p>
							<p class="bungee text-uppercase pink--text text-center fs--22 mb-0">
								{{ promoWeek.prizeName }}
							</p>
							<p class="text-center fs--12 fw--800 mb-1">MSRP {{ promoWeek.msrp }}</p>
						</v-col>
						<v-col cols="6">
							<p class="fs--13 fw--600 text-center">
								<span class="text-green">Watch the video</span>
								and
								<span class="text-green">visit obsbot.com</span>
								to earn
								<span class="pink--text">Giveaway Entries!</span>
							</p>
							<div class="d-flex align-center flex-column justify-space-between mt-3">
								<div class="watch-video">
									<v-btn
										v-if="!isVideoEnded && !isVideoClaimed"
										depressed
										color="#6D6D6D"
										width="170"
										class="py-5"
										@click="actions.toggleVideo">
										<v-icon color="white" size="16">{{ isPlaying ? pauseIcon : playIcon }}</v-icon>
										<strong class="fs--12">
											{{ isPlaying ? 'Pause the video' : 'Watch the video' }}
										</strong>
									</v-btn>
									<v-btn
										v-if="isVideoEnded && !isVideoClaimed"
										depressed
										width="170"
										class="py-5"
										color="pink"
										@click="actions.claimEntries('video')">
										<strong class="fs--12">Claim Entries</strong>
									</v-btn>
									<v-btn
										v-if="isVideoClaimed"
										depressed
										width="170"
										class="py-5"
										color="#01bdbb"
										@click="actions.videoClaimed">
										<strong class="fs--12">Claimed!</strong>
									</v-btn>
									<p class="text-center mt-1">
										<strong class="text-green">+5</strong>
										<span class="text-uppercase pink--text fs--14">&nbsp;Bonus Entries</span>
									</p>
								</div>
								<div class="visit-obsbot">
									<v-btn
										v-if="!isVisitedObsBot"
										depressed
										class="py-5"
										width="170"
										color="pink"
										@click="visitOBS">
										<strong class="fs--12">Visit OBSBOT.com</strong>
									</v-btn>
									<v-btn
										v-if="isVisitedObsBot && !isVisitClaimed"
										depressed
										class="py-5"
										width="170"
										color="pink"
										@click="actions.claimEntries('visit')">
										<strong>Claim Entries</strong>
									</v-btn>
									<v-btn
										v-if="isVisitClaimed"
										depressed
										class="py-5"
										width="170"
										color="#01bdbb"
										@click="actions.visitClaimed">
										<strong>Claimed!</strong>
									</v-btn>
									<p class="text-center mt-1">
										<strong class="text-green">+3</strong>
										<span class="text-uppercase pink--text fs--14">&nbsp;Bonus Entries</span>
									</p>
								</div>
							</div>
							<p class="text-center fs--12 fw--800 mb-1">
								Week 1 Winner Selected on
								<span class="text-green" style="white-space: nowrap">
									{{ promoWeek.winnerSelectionDate }}
								</span>
							</p>
						</v-col>
						<v-col cols="12 text-center pb-2">
							<a class="text-green fs--12 text-underline mb-1" @click="termsPopup = true">
								See Terms &amp; Conditions
							</a>
						</v-col>
					</v-row>

					<v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters justify="center">
						<v-col cols="12" md="8" sm="12">
							<img
								v-if="showPlayArrow"
								class="playImg"
								src="/banners/obsbot/VideoPlay.png"
								width="100%"
								alt="Video Play"
								@click="actions.toggleVideo" />
							<div class="video-area">
								<video
									v-show="!showPlayArrow"
									ref="video"
									:width="$vuetify.breakpoint.mdAndUp ? 480 : '100%'"
									@timeupdate="actions.onTimeUpdate"
									@ended="actions.onVideoEnded">
									<source :src="promoWeek.video" type="video/mp4" />
									Your browser does not support HTML video.
								</video>
								<div v-if="!showPlayArrow" class="seekbar">
									<div class="progress-bar">
										<div class="progress" :style="{ width: progress + '%' }"></div>
									</div>
								</div>
							</div>

							<p class="fs--13 fw--600 text-center">
								<span class="text-green">Watch the video</span>
								and
								<span class="text-green">visit obsbot.com</span>
								to earn
								<span class="pink--text">Giveaway Entries!</span>
							</p>
							<div class="d-flex align-start justify-space-between mt-3">
								<div class="watch-video">
									<v-btn
										v-if="!isVideoEnded && !isVideoClaimed"
										depressed
										class="py-6"
										width="210"
										color="#6D6D6D"
										@click="actions.toggleVideo">
										<v-icon color="white">{{ isPlaying ? pauseIcon : playIcon }}</v-icon>
										<strong>{{ isPlaying ? 'Pause the video' : 'Watch the video' }}</strong>
									</v-btn>
									<v-btn
										v-if="isVideoEnded && !isVideoClaimed"
										depressed
										class="py-6"
										width="210"
										color="pink"
										@click="actions.claimEntries('video')">
										<strong>Claim Entries</strong>
									</v-btn>
									<v-btn
										v-if="isVideoClaimed"
										depressed
										class="py-6"
										width="210"
										color="#01bdbb"
										@click="actions.videoClaimed">
										<strong>Claimed!</strong>
									</v-btn>
									<p class="text-center mt-1">
										<strong class="text-green">+5</strong>
										<span class="text-uppercase pink--text">&nbsp;Bonus Entries</span>
									</p>
								</div>
								<v-icon color="white" class="ml-0 pl-3" size="50">{{ chevronRight }}</v-icon>
								<div class="visit-obsbot ml-3">
									<v-btn
										v-if="!isVisitedObsBot && !isVisitClaimed"
										depressed
										class="py-6"
										width="210"
										color="pink"
										@click="visitOBS">
										<strong>Visit OBSBOT.com</strong>
									</v-btn>
									<v-btn
										v-if="isVisitedObsBot && !isVisitClaimed"
										depressed
										class="py-6"
										width="210"
										color="pink"
										@click="actions.claimEntries('visit')">
										<strong>Claim Entries</strong>
									</v-btn>
									<v-btn
										v-if="isVisitClaimed"
										depressed
										class="py-6"
										width="210"
										color="#01bdbb"
										@click="actions.visitClaimed">
										<strong>Claimed!</strong>
									</v-btn>
									<p class="text-center mt-1">
										<strong class="text-green">+3</strong>
										<span class="text-uppercase pink--text">&nbsp;Bonus Entries</span>
									</p>
								</div>
							</div>
						</v-col>
						<v-col
							cols="12"
							md="3"
							offset="0"
							offset-sm="0"
							offset-md="1"
							sm="12"
							class="text-center px-0 d-flex align-center flex-column col-2">
							<div v-if="$vuetify.breakpoint.mdAndUp" class="camera-giveaway">
								<img src="/banners/obsbot/3x-cameragiveaway.png" class="giveaway" alt="" srcset="" />
							</div>
							<div class="camera">
								<img :src="promoWeek.image" alt="" />
							</div>
							<div class="text-center">
								<p class="bungee text-center mb-1">
									<strong class="">Week</strong>
									<strong class="pink--text fs--25 mx-2">{{ promoWeek.week }}</strong>
									<strong class="">OF</strong>
									<strong class="text-green fs--20 mx-2">{{ promoWeek.totalWeeks }}</strong>
								</p>
								<p class="bungee text-uppercase pink--text text-center fs--26 mb-1">
									{{ promoWeek.prizeName }}
								</p>
								<p class="text-center fs--12 fw--800 mb-1">MSRP {{ promoWeek.msrp }}</p>
								<p class="text-center fs--10 fw--800 mb-1">
									Week {{ promoWeek.week }} Winner Selected on
									<span class="text-green" style="white-space: nowrap">
										{{ promoWeek.winnerSelectionDate }}
									</span>
								</p>
								<a class="text-green fs--12 text-underline" @click="termsPopup = true">
									See Terms &amp; Conditions
								</a>
							</div>
						</v-col>
					</v-row>
				</div>
			</div>

			<TermsPopup :modal="termsPopup" @close="termsPopup = false" />
		</v-card>
	</v-dialog>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import router from '@/router';
import axios from '@axios';
import TermsPopup from '@/components/TermsPopup';
import { mdiClose, mdiLock, mdiPlayCircleOutline, mdiPauseCircleOutline, mdiChevronRight } from '@mdi/js';
import { trackEvent } from '@/utils/mixpanel';

export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		TermsPopup,
	},
	setup(props) {
		const termsPopup = ref(false);

		const video = ref(null);
		const duration = ref(null);
		const minutes = ref(null);
		const seconds = ref(null);
		const progress = ref(null);

		const showPlayArrow = ref(true);
		const isPlaying = ref(false);
		const isVideoEnded = ref(false);
		const isVisitedObsBot = ref(false);

		const isVideoClaimed = ref(false);
		const isVisitClaimed = ref(false);

		// Loaded from server API.
		const promoWeek = reactive({
			loaded: false,
			week: null,
			start: null,
			end: null,
			image: null,
			video: null,
			videoClaimed: false,
			videoClaimedAt: null,
			visitClaimed: false,
			visitClaimedAt: null,
		});

		onMounted(() => {
			axios.get('/promos/obsbot').then(res => {
				Object.assign(promoWeek, res.data);
				if (promoWeek.week != null) {
					isVideoClaimed.value = promoWeek.videoClaimed;

					isVisitClaimed.value = promoWeek.visitClaimed;
					isVisitedObsBot.value = promoWeek.visitClaimed;

					promoWeek.loaded = true;

					// local debug
					//isVideoEnded.value = true;
				}
			});

			// Track open event.
			trackEvent({
				type: 'Campaign_Open',
				params: {
					campaign_name: 'OBSBOT',
					page: getPageName(),
				},
			});
		});

		const getPageName = () => {
			if (router.currentRoute.meta && router.currentRoute.meta.page) {
				return router.currentRoute.meta.page;
			} else {
				return router.currentRoute.name;
			}
		};

		const actions = {
			toggleVideo() {
				if (!video.value) {
					setTimeout(() => {
						video.value.play();
						isPlaying.value = true;
					}, 10);

					return;
				}
				if (isPlaying.value) {
					video.value.pause();
					isPlaying.value = false;
				} else {
					video.value.play();
					isPlaying.value = true;

					if (showPlayArrow.value) {
						trackEvent({
							type: 'Campaign_VideoStart',
							params: {
								campaign_name: 'OBSBOT',
							},
						});
					}
				}

				if (isPlaying.value) {
					duration.value = actions.formatTime(video.value.duration);
					showPlayArrow.value = false;
				}
			},
			onVideoEnded() {
				isPlaying.value = false;
				isVideoEnded.value = true;
				showPlayArrow.value = true;

				trackEvent({
					type: 'Campaign_VideoComplete',
					params: {
						campaign_name: 'OBSBOT',
					},
				});
			},
			claimEntries(type) {
				let url;
				if (type === 'video') {
					url = '/promos/obsbot/claim-video';
				} else if (type === 'visit') {
					url = '/promos/obsbot/claim-visit';
				}

				axios.post(url).then(() => {
					if (type === 'video') {
						isVideoClaimed.value = true;
					} else if (type === 'visit') {
						isVisitClaimed.value = true;
					}

					trackEvent({
						type: 'Campaign_Giveaway_EntryDeposit',
						params: {
							campaign_name: 'OBSBOT',
							type: type === 'video' ? 'Video Complete' : 'Visit Website',
							entries: type === 'video' ? 5 : 3,
						},
					});

					const audio = new Audio();
					audio.src = require('/public/banners/obsbot/Claimed-Audio.mp3');
					audio.crossOrigin = 'anonymous';
					audio.play();
				});
			},
			videoClaimed() {
				// no-op
			},
			visitClaimed() {
				// no-op
			},
			play() {
				video.value.play();
			},
			pause() {
				video.value.pause();
				isPlaying.value = false;
			},
			onTimeUpdate(value) {
				actions.formatTime(value.target.currentTime);
			},
			formatTime(time) {
				minutes.value = Math.floor(time / 60);
				const secs = Math.floor(time % 60);
				seconds.value = `${secs < 10 ? '0' : ''}${secs}`;

				progress.value = Math.floor((video.value.currentTime / video.value.duration) * 100);

				return `${minutes.value}:${seconds.value}`;
			},
		};

		const visitOBS = () => {
			window.open(promoWeek.visitSiteUrl, '_blank');
			isVisitedObsBot.value = true;
			actions.claimEntries('visit');
		};

		return {
			promoWeek,
			video,
			duration,
			minutes,
			seconds,
			progress,
			showPlayArrow,
			isPlaying,
			isVideoEnded,
			isVisitedObsBot,
			isVideoClaimed,
			isVisitClaimed,
			actions,
			termsPopup,
			closeIcon: mdiClose,
			lockIcon: mdiLock,
			playIcon: mdiPlayCircleOutline,
			pauseIcon: mdiPauseCircleOutline,
			chevronRight: mdiChevronRight,
			visitOBS,
		};
	},
};
</script>

<style lang="scss" scoped>
.v-card {
	border-radius: 4px 4px 0 0 !important;
}

.close-icon {
	position: absolute;
	top: 2px;
	right: 5px;
}

.campaign-body {
	background: url('/banners/obsbot/BackgroundPop.png');
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: -30px !important;

	@media screen and (max-width: 959px) {
		background-size: 160%;
		margin-top: -40px !important;
		max-height: 95vh;

		.mobile-giveaway {
			z-index: 9;
			margin-top: 0px;
			width: 150px;
			height: 62px;
		}
	}
}

.bungee {
	font-family: 'Bungee', sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.text-green {
	color: #01bdbb;
}

.overflow-y-scroll {
	overflow-y: auto !important;
	overflow-x: hidden !important;
	border-radius: 0 0 4px 4px !important;
}

.content-section {
	width: 90%;
	margin: auto;

	.stream-logo {
		z-index: 9;
		margin-left: -10px;
		@media screen and (max-width: 959px) {
			margin-left: 10px;
			width: 129px !important;
			height: 81px !important;
		}
	}

	img.plus {
		width: 40px;
		height: 40px;
		@media screen and (max-width: 959px) {
			width: 25px !important;
			height: 25px !important;
			margin-left: -10px !important;
		}
	}

	img.obs-bot {
		margin-left: -10px;
		z-index: 9;
		@media screen and (max-width: 959px) {
			margin-left: -20px;
			width: 150px !important;
			height: 80px !important;
		}
	}

	img.giveaway {
		margin-top: -100px;
	}

	.video-area {
		position: relative;
		.seekbar {
			position: absolute;
			z-index: 99;
			width: 92%;
			left: 4%;
			bottom: 20px;
			.progress-bar {
				z-index: 99;
				width: 100%;
				left: 0%;
				bottom: 0px;
				background: #d4d4d4;
				border-radius: 4px;
				height: 4px;
			}
			.progress {
				position: absolute;
				z-index: 100;
				width: 0%;
				left: 0%;
				bottom: 0px;
				background: #01bdbb;
				border-radius: 4px;
				height: 4px;
			}
		}
	}

	video {
		margin-top: -25px;
		margin-left: 0px;
		border: 2px solid #01bdbb;
	}

	img.playImg {
		margin-top: -25px;
		margin-left: 0px;
	}
}
</style>
