import axios from '@axios';
import store from '@/store';
import { MaxFileSizes, GlobalTtsDisplaySize } from './constants';
import { dataUrlToBlob, isNumber } from './utils.js';

const getExtensionStatus = function (callback) {
	axios.get('/extension-status').then(res => {
		if (callback) {
			callback(res.data);
		}
		store.commit('app/SET_TTS_CO_HOST_SLOT_STATUSES', res.data.coHostSlotStatuses);
	});
};

/**
 * Called after an image is selected from the file dialog.
 *
 * @param {*} e
 * @param {*} sticker
 * @param {*} afterScaleCallback Function called after image has been
 * scaled and set to sticker.stickerBlob.
 * @param {*} errorCallback Function called if image scaling fails.
 */
const onImagePicked = function (e, sticker, afterScaleCallback, errorCallback) {
	if (e) {
		const file = e.target.files[0];
		if (file) {
			if (file.size > MaxFileSizes.IMAGE) {
				const err = `Maximum image size is ${MaxFileSizes.IMAGE / 1000000}MB.`;
				if (errorCallback) {
					errorCallback(err);
				} else {
					console.error(err);
				}
				e.target.value = null;
				return;
			}

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = function () {
				scaleImage(sticker, reader.result, afterScaleCallback, errorCallback);
			};

			reader.onerror = function () {
				console.log(reader.error);
			};
		}
	}
};

/**
 * Called after an image is selected from the file dialog.
 *
 * @param {*} e
 * @param {*} sticker
 * @param {*} afterPickedCallback Function called after audio has been
 * set to sticker.audioBlob.
 * @param {*} errorCallback Function called if the audio file selection fails.
 */
const onAudioPicked = function (e, sticker, afterPickedCallback, errorCallback) {
	if (e) {
		const file = e.target.files[0];
		const validTypes = ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav'];

		const handleError = msg => {
			if (errorCallback) {
				errorCallback(msg);
			} else {
				console.error(msg);
			}
			e.target.value = null;
		};

		if (file) {
			// Validate.
			if (file.size > MaxFileSizes.AUDIO) {
				handleError(`Maximum audio size is ${MaxFileSizes.AUDIO / 1000000}MB.`);
				return;
			} else if (!validTypes.includes(file.type)) {
				handleError(
					'Sorry! This format is not supported. Audio must be uploaded in either WAV or MP3 file formats.',
				);
				return;
			}

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = function () {
				sticker.audioBlob = reader.result;
				if (afterPickedCallback) afterPickedCallback();
			};

			reader.onerror = function () {
				console.log(reader.error);
			};
		}
	}
};

const onAlertPicked = function (e, sticker, afterPickedCallback, errorCallback) {
	if (e) {
		const file = e.target.files[0];
		const validTypes = ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav'];

		const handleError = msg => {
			if (errorCallback) {
				errorCallback(msg);
			} else {
				console.error(msg);
			}
			e.target.value = null;
		};

		if (file) {
			// Validate.
			if (file.size > MaxFileSizes.AUDIO) {
				handleError(`Maximum audio size is ${MaxFileSizes.AUDIO / 1000000}MB.`);
				return;
			} else if (!validTypes.includes(file.type)) {
				handleError(
					'Sorry! This format is not supported. Audio must be uploaded in either WAV or MP3 file formats.',
				);
				return;
			}

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = function () {
				sticker.tts.alertSoundBlob = reader.result;
				if (afterPickedCallback) afterPickedCallback();
			};

			reader.onerror = function () {
				console.log(reader.error);
			};
		}
	}
};

/**
 * Scales an image and sets the result to the model attribute. Also
 * resets the source of the image preview ref.
 *
 * @param {*} sticker
 * @param {*} dataUrl
 * @param {*} afterScaleCallback Function called after image has been
 * scaled and set to sticker.stickerBlob.
 * @param {*} errorCallback Function called if image scaling fails.
 */
const scaleImage = function (sticker, dataUrl, afterScaleCallback, errorCallback) {
	const MAX_LONGEST_SIZE = 350;
	const payload = { image: dataUrl, longestSide: MAX_LONGEST_SIZE };
	axios
		.post('/custom-sticker/scale-image', payload)
		.then(res => {
			sticker.stickerBlob = res.data.result;
			if (afterScaleCallback) afterScaleCallback();
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(error);
			} else {
				console.error('Error caught on upload: ' + error.response.data.error);
			}
		});
};

const createObjectUrlFromBlob = imageBlob => {
	return URL.createObjectURL(dataUrlToBlob(imageBlob));
};

// Converts a file to Base64 for upload.
const fileToBase64 = async function (file) {
	return await new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			const blob = reader.result;
			resolve(blob);
		};
	});
};

// Parse bits from a sku.
const parseBits = function (sku) {
	if (!sku) return '';
	const pos = sku.indexOf('_');
	if (pos >= 0) {
		return sku.substring(pos + 1, sku.length);
	} else {
		return sku;
	}
};

/**
 * Some range values are stored in the database on a 100 scale, but the stored value
 * needs to be converted to an actual value (e.g. a stickness of 71 is 34.85 seconds.)
 */
const rangeValueConverter = {
	toUiValue: (dbValue, minValue, maxValue, maxRangeValue) => {
		if (isNumber(dbValue)) {
			return Math.round((dbValue / maxRangeValue) * (maxValue - minValue) + minValue);
		} else {
			return null;
		}
	},

	toDbValue: (uiValue, minValue, maxValue, maxRangeValue) => {
		return Math.round(((uiValue - minValue) / (maxValue - minValue)) * maxRangeValue);
	},
};

// Streamer configuration object.
const streamerConfig = {
	isLoaded: false,
	cooldownTime: null,
	masterVolume: null,

	newGlobalStickerSku: null,
	newGlobalStickerStickiness: null,
	newGlobalStickerEnabled: null,
	newGlobalStickerVolume: null,

	newTtsStickerSku: null,
	newTtsStickerStickiness: null,
	newTtsStickerVolume: null,
	newGlobalTtsStickerEnabled: null,

	globalStickerSize: null,
	componentButtonPosition: null,
	partyModeEnabled: null,
	partyModeGoal: null,
	partyModeMusic: null,
	partyModeSounds: null,
	partyModeConfetti: null,
	partyModeSeparateView: null,
	partyModeDisabledAnimation: null,
	partyModeLimitStickerCount: null,
	partyModeCooldownTime: null,
	sha256: null,
	ver: null,
	chatEnabled: null,
	chatText: null,
	overlayEnabled: null,
	overlayFontSize: null,
	overlayColor: null,
	overlayOutline: null,
	overlayText: null,
	overlayOnTop: null,
	overlayFontFamily: null,
	hideOverlayStroke: null,
	stickerPlacementPostion: null,
	partyModePosition: null,
	panelTheme: null,
	stickerPartyTracks: [],
	isPlacementGlobalEnabled: true,
	isPlacementCustomEnabled: true,
	isNotifyOnBrowserSourceInactive: true,

	globalTtsDisplaySize: GlobalTtsDisplaySize.REGULAR,
};

export {
	onImagePicked,
	onAudioPicked,
	onAlertPicked,
	createObjectUrlFromBlob,
	fileToBase64,
	getExtensionStatus,
	parseBits,
	streamerConfig,
	rangeValueConverter,
};
