import {
	mdiAccountOutline,
	mdiChartLine,
	mdiCogOutline,
	mdiBookmarkOutline,
	mdiEmoticonWinkOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
	mdiNewspaperVariantOutline,
} from '@mdi/js';

export default [
	{
		title: 'Dashboard',
		type: 'icon',
		icon: mdiNewspaperVariantOutline,
		to: 'home',
		id: 'home-page',
		step: '',
	},
	{
		title: 'Stickers',
		type: 'image',
		icon: 'Stickers.svg',
		id: 'stickers-page',
		to: 'streamer-stickers',
		step: 'stickers',
		children: [
			{
				id: 'custom-tab',
				title: 'Custom Stickers',
				to: '/stickers?tab=custom',
				tab: 'custom',
				image: 'Custom Stickers.svg',
			},
			{
				id: 'reward-tab',
				title: 'Reward Stickers',
				to: '/stickers?tab=reward',
				image: 'Reward Stickers.svg',
				tab: 'reward',
			},
			{
				id: 'gloabl-tab',
				title: 'Global Stickers',
				to: '/stickers?tab=global',
				tab: 'global',
				image: 'Global Stickers.svg',
			},
		],
	},
	{
		title: 'A.I. Text to Speech',
		type: 'image',
		icon: 'AI TTS.svg',
		id: 'ai-stickers-page',
		to: 'streamer-stickers-tts',
		step: 'ai-stickers',
		children: [
			{
				id: 'custom-ai-tab',
				title: 'Custom A.I. TTS',
				to: '/stickers-tts?tab=custom-ai-tts',
				tab: 'custom-ai-tts',
				image: 'Custom AI.svg',
			},
			{
				id: 'global-ai-tab',
				title: 'Global A.I. TTS',
				to: '/stickers-tts?tab=global-ai-tts',
				tab: 'global-ai-tts',
				image: 'Global AI.svg',
				tag: 'New',
			},
		],
	},
	{
		title: 'TTS Voice Lab',
		type: 'image',
		icon: 'mic.svg',
		to: 'streamer-tts-voice-lab',
		id: 'tts-voice-lab-page',
		step: 'tts-voice-lab',
	},
	{
		title: 'Sticker Party',
		type: 'image',
		icon: 'Sticker Party.png',
		to: 'streamer-sticker-party',
		id: 'stickers-party-page',
		step: 'sticker-party',
	},
	{
		title: 'Stick-O-Vision',
		type: 'image',
		icon: 'Stick Vision.svg',
		to: 'stick-o-vision',
		id: 'stick-o-vision-page',
		step: 'stick-o-vision',
	},
	{
		title: 'Chat Bot',
		type: 'image',
		icon: 'Chat Bot.svg',
		to: 'streamer-chat-bot',
		id: 'chatbot-page',
		step: '',
	},
	{
		title: 'Insights',
		type: 'image',
		icon: 'chart.svg',
		to: 'streamer-insights',
		id: 'insights-page',
		step: '',
	},
	{
		title: 'Settings',
		type: 'image',
		icon: 'Settings.svg',
		to: 'streamer-settings',
		id: 'settings-page',
		step: 'settings',
	},
	// {
	// 	title: 'Subscriptions',
	// 	type: 'icon',
	// 	icon: mdiBookmarkOutline,
	// 	to: 'subscriptions',
	// 	id: 'subscriptions-page',
	// 	step: 'subscriptions',
	// },
	// {
	// 	title: 'Tutorial',
	// 	icon: mdiAccountOutline,
	// 	to: 'tutorial',
	// },
];
