import store from '@/store';
import mixpanel from 'mixpanel-browser';
import { MIX_PANEL_KEYS } from '@/utils/constants';
import { getEndpointHost } from '@/utils/config';

const host = getEndpointHost();
let key = MIX_PANEL_KEYS.DEV;
if (host && !host.includes('dev')) {
	key = MIX_PANEL_KEYS.PROD;
}

mixpanel.init(key, { debug: false, track_pageview: false, persistence: 'localStorage' });

export function setProperties() {
	const a = store.state.app.analytics;

	if (a.internalUserId) {
		mixpanel.identify(a.internalUserId);

		// Set Super Properties
		mixpanel.register({
			MasterLocation: 'Dashboard',
			MasterLocationType: 'Dashboard',
			twitchId: a.twitchId || null,
			twitchName: a.twitchName || null,
			twitchEmailOptInStatus: a.twitchEmailOptInStatus || false,
			twitchAffiliateStatus: a.twitchAffiliateStatus || 'Non-Affiliate',
		});

		// Set People Properties
		mixpanel.people.set({
			twitchId: a.twitchId || null,
			twitchName: a.twitchName || null,
			twitchEmailOptInStatus: a.twitchEmailOptInStatus || false,
			twitchAffiliateStatus: a.twitchAffiliateStatus || 'Non-Affiliate',
		});
	}
}

export function trackEvent(event) {
	mixpanel.track(event.type, {
		...event.params,
	});
}

export function trackPageView(page) {
	mixpanel.track_pageview({ page: page });
}

export default mixpanel;
