<template>
	<v-dialog :max-width="maxWidth" v-model="showPopup" :persistent="persistent">
		<v-card>
			<div class="popup-header">
				<h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
					<slot name="primaryHeader"></slot>
				</h3>
				<v-icon class="close-icon cursor-pointer" @click="closePopup">{{ closeIcon }}</v-icon>
			</div>
			<v-card-text>
				<div class="content-section text-center mt-10 mb-3">
					<h3 v-if="$slots.secondaryHeader" class="fs--18 fw--400 mb-4">
						<slot name="secondaryHeader"></slot>
					</h3>
					<slot v-if="$slots.headerImage" name="headerImage"></slot>

					<slot v-if="$slots.embed" name="embed"></slot>
					<slot></slot>
					<v-btn v-if="closeButton" color="pink" class="px-15 mt-3" depressed large @click="closePopup">{{
						closeButtonCopy
					}}</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { computed } from '@vue/composition-api';
import { mdiClose } from '@mdi/js';
export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		closeButton: {
			type: Boolean,
			default: true,
		},
		closeButtonCopy: {
			type: String,
			default: 'Close',
		},
		maxWidth: {
			type: Number,
			default: 550,
		},
	},
	setup(props, { emit }) {
		const showPopup = computed({
			get() {
				return props.modal;
			},
			set(newValue) {
				if (!newValue) {
					emit('close', true);
				}
			},
		});

		const closePopup = () => {
			emit('close', true);
		};
		return {
			closePopup,
			showPopup,
			closeIcon: mdiClose,
		};
	},
};
</script>

<style lang="scss" scoped>
.popup-header {
	position: relative;
	padding: 12px 0;
	min-height: 56px;
	background: url('../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}

.content-section {
	width: 90%;
	margin: auto;
	height: auto;
	max-height: 550px;
	overflow-y: auto !important;
}
</style>
