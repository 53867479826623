import { StickerType } from '../utils/constants.js';

const promo = {
	// cms/src/@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue
	nav: {
		isEnabled: true,
		image: '/banners/200x300-FollowOnX.png',
		url: 'https://twitter.com/StreamStickers',
		ga: {
			event_name: 'campaign_banner_click',
			event_category: 'engagement',
			event_label: 'User Clicked the Campaign Banner',
			title: 'Twitter Promotion',
			unit: '200x300',
		},
	},
	// cms/src/views/streamer/home/promotion/Banners.vue
	main: {
		isEnabled: true,
		image: {
			left: '/banners/global-tts/TopBanner.png',
			right: '/banners/global-tts/BottomBanner.png',
		},
		url: '/stickers-tts?tab=' + StickerType.GLOBAL_AI_TTS.replaceAll('_', '-').toLowerCase(),
		urlSameWindow: true,
		ga: {
			event_category: 'engagement',
			event_label: 'User Clicked the Campaign Banner',
			title: 'Global A.I. TTS',
			unit: '728x90',
		},
		actionCopy: null,
		actionUrl: null,
		actionGa: {
			event_category: 'engagement',
			event_label: null,
			title: null,
			unit: null,
		},
	},
};

export default promo;
