<template>
	<div class="tutorial-dialog">
		<v-dialog v-model="show" max-width="700" persistent style="overflow: visible">
			<v-card>
				<div class="tutorial-header text-center">
					<img :src="appLogo" width="130" alt="Stream Stickers" />
					<h3 class="text-capitalize text-center d-none d-sm-block fs--18 fw--500">
						Welcome! Let's get you set up!
					</h3>

					<h3 class="text-capitalize text-center d-block d-sm-none fs--14 fw--500">
						Welcome! Let's get you set up!
					</h3>

					<v-icon class="close-icon" @click="close">{{ closeIcon }}</v-icon>
				</div>
				<!-- <v-divider class="purple"></v-divider> -->
				<v-card-text class="pa-0">
					<div class="welcome-stepper">
						<v-stepper class="px-0" flat v-model="step">
							<v-stepper-header class="px-0">
								<v-stepper-step :step="1">
									<Step :step="step" :current="1" title="Enable" subtitle="Extension" />
								</v-stepper-step>

								<v-divider :class="addLineClass(1)"></v-divider>

								<v-stepper-step :step="2">
									<Step :step="step" :current="2" title="Configure" subtitle="Streaming Software" />
								</v-stepper-step>

								<v-divider :class="addLineClass(2)"></v-divider>

								<v-stepper-step :step="3">
									<Step :step="step" :current="3" title="Test" subtitle="Setup" />
								</v-stepper-step>
							</v-stepper-header>
							<v-divider class="purple"></v-divider>
							<v-stepper-items>
								<v-stepper-content class="px-0 pb-0 upload-content" :step="1">
									<ActivateExtension @onActivate="onActivate" />
								</v-stepper-content>
								<v-stepper-content class="px-0 pb-0 upload-content" :step="2">
									<EnableExtension v-if="step === 2" v-on:move="changeStep" />
								</v-stepper-content>
								<v-stepper-content class="px-0 pb-0 upload-content" :step="3">
									<TestSetup v-on:finish="close" v-on:back="step = 1" />
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import Step from '@/views/streamer/stickers/wizard/Step';
import ActivateExtension from './ActivateExtension';
import EnableExtension from './EnableExtension';
import TestSetup from './TestSetup';
import { parseAccessToken } from '@/utils/auth';
import { saveEvent } from '@/utils/gtag';
import { trackEvent } from '@/utils/mixpanel';
import { mdiClose } from '@mdi/js';

export default {
	components: { Step, ActivateExtension, EnableExtension, TestSetup },
	props: {
		show: { type: Boolean, default: false },
	},
	setup(_, ctx) {
		const step = ref(1);

		onMounted(() => {
			// GA event.
			saveEvent({
				event: 'tutorial_begin',
				params: {
					event_category: 'engagement',
					event_label: 'User Starts the Tutorial',
				},
			});

			// MixPanel event.
			const jwtClaims = parseAccessToken();

			trackEvent({
				type: 'Tutorial_Start',
				params: {
					'Twitch Name': jwtClaims.displayName,
				},
			});
		});

		const close = () => {
			ctx.emit('close', true);
		};

		const addLineClass = step => {
			if (step.value === step) {
				return 'step-dash purpleDivider';
			}
			if (step.value > step) {
				return 'step-dash pink';
			}
			if (step.value < step) {
				return 'step-dash purpleDivider';
			}
		};

		const changeStep = value => {
			step.value = value;
		};

		const onActivate = viewMode => {
			changeStep(2);
		};

		return {
			appLogo: themeConfig.app.logo,
			closeIcon: mdiClose,
			step,
			addLineClass,
			close,
			changeStep,
			onActivate,
		};
	},
};
</script>

<style lang="scss" scoped>
.v-dialog {
	overflow: visible !important;
}

.tutorial-header {
	position: relative;
	padding: 12px 0px;
	background: url('../../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;

	img {
		margin-top: -30px;
	}

	h3 {
		margin-top: -15px;
	}
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}
</style>
