<template>
	<v-dialog :max-width="800" v-model="modal" :persistent="true">
		<v-card color="black" height="40">
			<div class="px-2 d-flex align-center justify-space-between bg-black pt-1">
				<h2>Terms &amp; Conditions</h2>
				<v-icon color="white" class="bungee" @click="$emit('close')">{{ closeIcon }}</v-icon>
			</div>
		</v-card>
		<v-card color="black" height="600" class="overflow-y-scroll">
			<div class="campaign-body">
				<div class="content-section py-4">
					<h4 class="mt-2 mb-3">
						NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE THE CHANCES OF WINNING.
					</h4>

					<div class="fs--14">
						<p>
							<strong>Sponsor:</strong>
							<span>The Sponsor of the OBSBOT A.I. Camera Giveaway is OBSBOT.</span>
						</p>

						<p>
							<strong>Eligibility:</strong>
							<span>
								The OBSBOT A.I. Camera Giveaway (the "Sweepstakes") is only open to those who enter via
								x.com (formerly Twitter) or dashboard.streamstickers.com. The Sweepstakes is open
								worldwide and is void where prohibited by law. Employees, independent contractors,
								interns, officers, directors, and agents of OBSBOT (the "Sponsor"), their respective
								affiliates, subsidiaries, advertising and promotion agencies, suppliers, and their
								immediate family members and/or those living in the same household of each are not
								eligible to participate in the Sweepstakes. The Sweepstakes is subject to all applicable
								federal, state, and local laws and regulations. Void where prohibited.
							</span>
						</p>

						<p>
							<strong>Agreement to Rules:</strong>
							<span>
								By entering the Sweepstakes, the contestant ("You") agree to comply with and abide by
								these Rules and the decisions of the Sponsor, and you represent and warrant that you
								meet the eligibility requirements. In addition, you agree to accept the Sponsor's
								decisions as final and binding as it relates to the content of this campaign. Entrants
								should look solely to the Sponsor with any questions, comments, or issues related to the
								Sweepstakes.
							</span>
						</p>

						<p>
							<strong>Entry Period:</strong>
							<span>
								The Sweepstakes consists of three separate entry periods. Each entry period lasts one
								week, with a winner announced at the end of each week. The entry periods are as follows:
							</span>
						</p>

						<ul class="mb-3">
							<li>
								Weekly Entry Period 1: September 9, 2024, at 12:00 AM EDT - September 15, 2024, at 11:59
								PM EDT
							</li>
							<li>
								Weekly Entry Period 2: September 16, 2024, at 12:00 AM EDT - September 22, 2024, at
								11:59 PM EDT
							</li>
							<li>
								Weekly Entry Period 3: September 23, 2024, at 12:00 AM EDT - September 30, 2024, at
								11:59 PM EDT
							</li>
						</ul>

						<p>
							<strong>How to Enter:</strong>
							<span>
								The Sweepstakes must be entered by performing one of the following actions each week
								during the Entry Period:
							</span>
						</p>

						<ol>
							<li>
								<strong>Enter via x.com:</strong>
								Users can earn 1 entry per week by liking, following, and retweeting the giveaway post
								on X.com.
							</li>
							<li>
								<strong>Watch OBSBOT Promotional Video:</strong>
								Users can earn 5 entries per week by watching the OBSBOT promotional video via the
								dashboard on streamstickers.com.
							</li>
							<li>
								<strong>Visit OBSBOT Website:</strong>
								Users can earn 3 entries per week by visiting the OBSBOT website from the dashboard on
								streamstickers.com.
							</li>
						</ol>

						<p class="mt-2">
							Users can earn a
							<strong>maximum of 9 entries per week.</strong>
						</p>

						<p>
							<strong>Important:</strong>
							It is against the rules to create multiple or duplicate accounts in an attempt to earn
							additional entries. Any such actions will result in immediate disqualification at the sole
							discretion of the Sponsor.
						</p>

						<p>
							The entry must fulfill all Sweepstakes requirements, as specified, to be eligible to win a
							prize. Entries that are incomplete or do not adhere to the rules or specifications of the
							Sweepstakes may be disqualified at the sole discretion of the Sponsor. You must provide the
							information requested. If you use fraudulent methods or otherwise attempt to circumvent the
							rules of the Sweepstakes, your submission may be disqualified at the sole discretion of the
							Sponsor.
						</p>

						<p>
							<strong>Prizes:</strong>
							<span>
								The winner(s) of the Sweepstakes (the "Winner") will receive the following prizes:
							</span>
						</p>

						<ul class="mb-3">
							<li>
								<strong>Week 1:</strong>
								Tiny2Lite (1 Unit) - MSRP $179.00
							</li>
							<li>
								<strong>Week 2:</strong>
								Tiny2Lite (1 Unit) - MSRP $179.00
							</li>
							<li>
								<strong>Week 3:</strong>
								Tiny2 (1 Unit) - MSRP $499.00
							</li>
						</ul>

						<p>
							There will be three separate winners, one selected each week over the course of the
							three-week period.
						</p>

						<p>
							Actual/appraised value of prizes may differ at the time of prize award. The specifics of the
							prize shall be solely determined by the Sponsor. No cash or other prize substitution shall
							be permitted except at the Sponsor's discretion. The prize is non-transferable. Substitution
							of the prize or transfer/assignment of the prize to others or request for the cash
							equivalent of the prize by the Winner is not permitted. Any and all prize-related expenses,
							including without limitation any and all federal, state, and/or local taxes, shall be the
							sole responsibility of the Winner. Acceptance of the prize constitutes permission for the
							Sponsor to use the Winner’s name, likeness, and entry for purposes of advertising and trade
							without further compensation, unless prohibited by law.
						</p>

						<p>
							<strong>Odds of Winning:</strong>
							<span>The odds of winning depend on the number of eligible entries received.</span>
						</p>

						<p>
							<strong>Winner Selection and Notification:</strong>
							<span>
								The Winner will be selected by Random Draw under the supervision of the Sponsor. One
								Winner will be selected each week over the course of the three-week period. The Winner
								will be notified within 7 days of the winner selection. Winners will be announced on the
								Stream Stickers X.com social page. The Sponsor shall have no liability for a Winner's
								failure to receive notices due to spam, junk e-mail, or other security settings or for a
								Winner’s provision of incorrect or otherwise non-functioning contact information. If the
								Winner cannot be contacted, is ineligible, fails to claim the prize within 7 days from
								the time the award notification was sent, or fails to timely return a completed and
								executed declaration and release as required, the prize may be forfeited and an
								alternate Winner selected. Receipt of the prize offered in this Campaign by the Winner
								is conditioned upon compliance with any and all federal, state, and local laws and
								regulations. Any violation of these official rules by the Winner at the Sponsor's sole
								discretion will result in the Winner's disqualification as winner, and all privileges as
								winner will be immediately terminated. The Winner must be located in a region that is
								shippable by OBSBOT. OBSBOT has full discretion to invalidate any winners that reside
								outside their shippable regions.
							</span>
						</p>
						<p>
							<strong>Rights Granted by the Entrant:</strong>
							<span>
								By entering this content (e.g., photo, video, text, etc.), You understand and agree that
								the Sponsor and anyone acting on behalf of the Sponsor or its respective licensees,
								successors, and assigns, including TurboHype, LLC, shall have the right, where permitted
								by law, to print, publish, broadcast, distribute, and use in any media now known or
								hereafter developed, in perpetuity and throughout the World, without limitation, your
								entry, name, portrait, picture, voice, likeness, image, statements about the Campaign,
								and biographical information for news, publicity, information, trade, advertising,
								public relations, and promotional purposes without any further compensation, notice,
								review, or consent. By entering this content, you represent and warrant that your entry
								is an original work of authorship and does not violate any third party’s proprietary or
								intellectual property rights. If your entry infringes upon the intellectual property
								rights of another, you will be disqualified at the sole discretion of the Sponsor. If
								the content of your entry is claimed to constitute infringement of any proprietary or
								intellectual property rights of any third party, you shall, at your sole expense, defend
								or settle against such claims. You shall indemnify, defend, and hold harmless OBSBOT and
								TurboHype, LLC from and against any suit, proceeding, claims, liability, loss, damage,
								costs, or expense, which OBSBOT and TurboHype, LLC may incur, suffer, or be required to
								pay arising out of such infringement or suspected infringement of any third party’s
								right.
							</span>
						</p>
						<p>
							<strong>Terms and Conditions:</strong>
							<span>
								The Sponsor reserves the right, in its sole discretion, to cancel, terminate, modify, or
								suspend the Sweepstakes should virus, bug, non-authorized human intervention, fraud, or
								other cause beyond the Sponsor’s control corrupt or affect the administration, security,
								fairness, or proper conduct of the Sweepstakes. In such cases, the Sponsor may select a
								winner(s) from eligible entries received before and/or after the action taken by the
								Sponsor if appropriate. The Sponsor reserves the right at its sole discretion to
								disqualify any individual who tampers or attempts to tamper with the entry process or
								the operation of the Sweepstakes or website or violates these Terms & Conditions. The
								Sponsor has the right, in its sole discretion, to maintain the integrity of the
								Sweepstakes, to void entries for any reason, including, but not limited to: multiple
								entries from the same user from different IP addresses; multiple entries from the same
								computer in excess of that allowed by Sweepstakes rules; or the use of bots, macros,
								scripts, or other technical means for entering. Any attempt by an entrant to
								deliberately damage any website or undermine the legitimate operation of the Sweepstakes
								may be a violation of criminal and civil laws. Should such an attempt be made, the
								Sponsor reserves the right to seek damages to the fullest extent permitted by law.
							</span>
						</p>
						<p>
							<strong>Limitation of Liability:</strong>
							<span>
								By entering the Sweepstakes, you agree to release and hold harmless OBSBOT, TurboHype,
								LLC, and their subsidiaries, affiliates, advertising and promotion agencies, partners,
								representatives, agents, successors, assigns, employees, officers, and directors from
								any liability, illness, injury, death, loss, litigation, claim, or damage that may
								occur, directly or indirectly, whether caused by negligence or not, from (i) such
								entrant’s participation in the Sweepstakes and/or his/her acceptance, possession, use,
								or misuse of any prize or any portion thereof; (ii) unauthorized human intervention in
								any part of the Sweepstakes; (iii) electronic or human error in the administration of
								the Sweepstakes or the processing of entries; (iv) technical errors of any kind,
								including but not limited to the malfunction of any computer, cable, network, hardware,
								or software, or other mechanical equipment; (v) the unavailability or inaccessibility of
								any transmissions, telephone, or Internet service; (vi) printing errors; (vii) lost,
								late, postage-due, misdirected, or undeliverable mail.
							</span>
						</p>
						<p>
							<strong>Disputes:</strong>
							<span>
								This Sweepstakes is governed by the laws of the United States, without respect to
								conflict of law doctrines. By participating in this Campaign, you agree that any and all
								disputes that cannot be resolved between the parties, and causes of action arising out
								of or connected with this Campaign, shall be resolved individually, without resort to
								any form of class action, exclusively before a court located in the United States having
								jurisdiction. Further, in any such dispute, under no circumstances shall You be
								permitted to obtain awards for, and hereby waive all rights to, punitive, incidental, or
								consequential damages, including reasonable attorney’s fees, other than actual
								out-of-pocket expenses (i.e., costs associated with entering the Sweepstakes). You
								further waive all rights to have damages multiplied or increased.
							</span>
						</p>
						<p>
							<strong>Winners List:</strong>
							<span>
								To request a copy of the winners list, please contact the Sponsor. Requests must be sent
								within four (4) weeks of the end of the Entry Period.
							</span>
						</p>
						<p>
							<strong>
								The Sweepstakes is in no way sponsored, endorsed, administered by, or associated with
								Facebook, X (formerly Twitter), Instagram, Snapchat, YouTube, Reddit, Pinterest,
								LinkedIn, or any other social networks that are used to share the Sweepstakes.
							</strong>
							You understand that you are providing your information to the owner of the Sweepstakes and
							not to Facebook, X, Instagram, Snapchat, YouTube, Reddit, Pinterest, LinkedIn, or any other
							social networks.
						</p>

						<p>
							<strong>Acceptance of Rules:</strong>
							<span>
								By participating in the Sweepstakes, You have affirmatively reviewed, accepted, and
								agreed to all of the Official Rules, Terms, and Conditions.
							</span>
						</p>

						<div class="text-center mt-3">
							<v-btn color="pink" depressed @click="$emit('close')">Close</v-btn>
						</div>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiClose, mdiLock } from '@mdi/js';
export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return {
			closeIcon: mdiClose,
			lockIcon: mdiLock,
		};
	},
};
</script>

<style lang="scss" scoped>
.terms-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
.close-icon {
	position: absolute;
	top: 5px;
	right: 5px;
}

.bungee {
	font-family: 'Bungee', sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.text-green {
	color: #01bdbb;
}

.content-section {
	width: 90%;
	margin: auto;
}

.overflow-y-scroll {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 0 0 4px 4px;
}
</style>
