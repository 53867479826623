<template>
	<div class="music-player">
		<div v-if="isWaveLoading" class="text-center mt-3">
			<span class="fs--14 shinyPurple--text">Loading Voice Model...</span>
		</div>
		<div class="d-flex align-center">
			<v-btn v-if="!isWaveLoading" fab x-small color="white" class="mr-4" depressed @click="actions.play()">
				<v-icon color="pink">{{ isPlaying ? icons.pause : icons.play }}</v-icon>
			</v-btn>
			<div :id="id" class="waveform" style="width: 100%; max-width: 100%; height: 50px; overflow-x: hidden"></div>
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted, ref, toRef, watch } from '@vue/composition-api';
import WaveSurfer from 'wavesurfer.js';
import { mdiPlay, mdiPause } from '@mdi/js';

export default {
	props: {
		id: {
			type: String,
		},
		audio: {
			type: String,
		},
		uploadPreview: {
			type: Boolean,
			default: false,
		},
		volume: {
			type: Number,
			default: 1,
		},
	},
	setup(props, ctx) {
		let wavesurfer = null;
		const isPlaying = ref(false);
		const isWaveLoading = ref(false);

		// Watch for changes in the audio prop so we can reload.
		const audioUrl = toRef(props, 'audio');
		watch(audioUrl, newVal => {
			loadAudio();
		});

		onMounted(() => {
			loadAudio();
		});

		onUnmounted(() => {
			wavesurfer.destroy();
		});

		const loadAudio = () => {
			if (!wavesurfer) {
				wavesurfer = WaveSurfer.create({
					container: '#' + props.id,
					height: 50,
					waveColor: '#51f2f7',
					progressColor: '#f801fa',
					cursorColor: '#f801fa',
					barGap: 3,
					barWidth: 3,
					barHeight: 100,
					barRadius: 2,
					fillParent: true,
					hideScrollbar: true,
					normalize: true,
					url: props.audio,
				});
			}

			wavesurfer.load(audioUrl.value);

			isWaveLoading.value = true;

			wavesurfer.on('ready', function () {
				isWaveLoading.value = false;
			});

			wavesurfer.on('audioprocess', () => {
				const currentTime = wavesurfer.getCurrentTime();

				// Trigger once per second
				if (Math.floor(currentTime) % 1 === 0) {
					const averageFrequency = actions.getAverageFrequency(wavesurfer.backend.analyser);
					// console.log(`Average Frequency at ${currentTime.toFixed(0)}s:`, averageFrequency);

					// Perform other tasks with `averageFrequency`

					ctx.emit('frequency', averageFrequency);
				}
			});
		};

		const actions = {
			play() {
				if (wavesurfer.isPlaying()) {
					wavesurfer.pause();
					isPlaying.value = false;
					ctx.emit('frequency', 0);
				} else {
					wavesurfer.setVolume(props.volume);
					wavesurfer.play();
					isPlaying.value = true;

					wavesurfer.on('finish', () => {
						isPlaying.value = false;
						ctx.emit('frequency', 0);
					});
				}
			},

			getAverageFrequency(analyser) {
				const frequencyData = new Uint8Array(analyser.frequencyBinCount);
				analyser.getByteFrequencyData(frequencyData);

				// Calculate average frequency value
				const sum = frequencyData.reduce((acc, value) => acc + value, 0);
				return sum / frequencyData.length;
			}
		};

		return {
			isPlaying,
			isWaveLoading,
			wavesurfer,
			actions,
			icons: {
				play: mdiPlay,
				pause: mdiPause,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.waveform {
	width: 100%;
	max-width: 100% !important;
	overflow: hidden !important;
}

/* #waveform::part(cursor) {
    height: 120px;
    top: 28px;
    border-radius: 4px;
    border: 1px solid #fff;
    display: none;
} */

/* #waveform ::part(cursor):after {
    content: '🏄';
    font-size: 1.5em;
    position: absolute;
    left: 0;
    top: -28px;
    transform: translateX(-50%);
} */
</style>
