import { getEndpointHost } from './config';
import { getAccessToken } from './auth';
import store from '@/store';

// Preview URL with pre-defined sticker copy.
const getAudioPreviewUrl = (voiceId, endpoint) => {
	let url = `https://${getEndpointHost()}/cms2/custom-sticker/tts/${endpoint}`;
	url += `?voiceId=${voiceId}`;
	url += `&auth=${getAccessToken()}`;

	return url;
};

// Preview URL with pre-defined sticker copy for global TTS stickers.
const getGlobalAudioPreviewUrl = (stickerId, endpoint) => {
	let url = `https://${getEndpointHost()}/cms2/global-sticker/tts/${endpoint}`;
	url += `?stickerId=${stickerId}`;
	url += `&auth=${getAccessToken()}`;

	return url;
};

// Preview URL with pre-defined voice copy.
const getVoicePreviewUrl = voiceId => {
	let url = `https://${getEndpointHost()}/cms2/tts/vlab/voice-preview`;
	url += `?voiceId=${voiceId}`;
	url += `&auth=${getAccessToken()}`;

	return url;
};

// Preview URL for a voice in the process of being created.
const getVoicePreviewUrlInProcess = (voiceId, isAccentEnhancement, stability, style) => {
	let url = `https://${getEndpointHost()}/cms2/tts/vlab/voice-preview-in-process`;
	url += `?voiceId=${voiceId}`;
	url += `&auth=${getAccessToken()}`;

	url += `&isAccentEnhancement=${isAccentEnhancement}`;
	url += `&stability=${stability}`;
	url += `&style=${style}`;

	return url;
};

const elSupportedLangs = () => {
	return store.state.app.elSupportedLangs;
};

const elSupportedLangsNonEnglish = () => {
	return elSupportedLangs().filter(v => v !== 'English');
};

const formatSupportedLangs = langs => {
	let s = '';
	let ctr = 0;
	const maxPerLine = 5;
	for (let lang of langs) {
		if (s.length == 0) {
			s = lang;
		} else {
			if (ctr >= maxPerLine) {
				s += ',<br />' + lang;
				ctr = 0;
			} else {
				s += ', ' + lang;
			}
		}
		ctr++;
	}

	return s;
};

export {
	getAudioPreviewUrl,
	getGlobalAudioPreviewUrl,
	getVoicePreviewUrl,
	getVoicePreviewUrlInProcess,
	elSupportedLangs,
	elSupportedLangsNonEnglish,
	formatSupportedLangs,
};
