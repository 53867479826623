import axios from '@axios';
import { TtsAudioSource, VoiceLabOwnership, VoiceType } from '@/utils/constants';

export default {
	namespaced: true,
	state: {
		shallContentShowOverlay: false,
		tutorialStep: null,
		showTutorial: false,
		ttsVoices: [],
		ttsCoHostSlotStatuses: [],
		messages: [],
		globalErrorMessage: null,
		googleFonts: [],
		elSupportedLangs: [
			'English',
			'Spanish',
			'Portuguese',
			'French',
			'German',
			'Italian',
			'Korean',
			'Japanese',
			'Chinese',
			'Hindi',
			'Indonesian',
			'Dutch',
			'Turkish',
			'Filipino',
			'Polish',
			'Swedish',
			'Bulgarian',
			'Romanian',
			'Arabic',
			'Czech',
			'Greek',
			'Finnish',
			'Croatian',
			'Malay',
			'Slovak',
			'Danish',
			'Tamil',
			'Ukrainian',
		],
		analytics: {
			internalUserId: null,
			twitchId: null,
			twitchName: null,
			twitchEmailOptInStatus: false,
			twitchAffiliateStatus: null,
			anyGlobalTtsEnabled: false,
		},
		activatedExtensions: [],
	},
	getters: {},
	mutations: {
		SET_FONTS(state, value) {
			state.googleFonts = [...value];
		},
		TOGGLE_CONTENT_OVERLAY(state, value) {
			state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay;
		},
		SET_STEP(state, value) {
			state.tutorialStep = value;
		},
		SET_TUTORIAL(state, value) {
			state.showTutorial = value;
		},
		SET_TTS_VOICES(state, value) {
			// Apply customizations to server data.
			for (const v of value) {
				// Set voice type based on audio source.
				if (v.audioSource == TtsAudioSource.AMAZON) {
					v.voiceType = VoiceType.STANDARD;
				} else if (v.audioSource == TtsAudioSource.ELEVEN_LABS) {
					v.voiceType = VoiceType.AI;
				} else {
					console.warn(`Voice ${v.name} has unknown audioSource ${v.audioSource}.`);
				}

				// Change label for shared voices.
				if (v.audioSource == TtsAudioSource.ELEVEN_LABS) {
					if (v.ownership == VoiceLabOwnership.GLOBAL) {
						v.name = `[A.I.] ${v.name}`;
					} else if (v.ownership == VoiceLabOwnership.STREAMER) {
						v.name = `[A.I. Personal] ${v.name}`;
					} else if (v.ownership == VoiceLabOwnership.SHARED_TO) {
						v.name = `[A.I. Shared] ${v.name}`;
					}
				}
			}
			state.ttsVoices = value;
		},
		SET_TTS_CO_HOST_SLOT_STATUSES(state, value) {
			state.ttsCoHostSlotStatuses = value;
		},
		SET_MESSAGES(state, value) {
			state.messages = value;
		},
		SET_GLOBAL_ERROR_MESSAGE(state, value) {
			state.globalErrorMessage = value;
		},
		SET_ANALYTICS(state, value) {
			state.analytics = value;
		},
		SET_ACTIVATED_EXTENSIONS(state, value) {
			state.activatedExtensions = value;
		},
	},
	actions: {
		// Save Google Analytic
		saveAnalytic({ commit }, payload) {
			const { event, params } = payload;
			//console.log('Save Event.', payload);
			// eslint-disable-next-line
			gtag('event', event, {
				...params,
			});
		},
		// Load Google Fonts
		loadGoogleFonts({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				axios
					.get('/web-fonts')
					.then(resp => {
						const bebasNeue = resp.data.items.find(font => font.family === 'Bebas Neue');

						const inter = resp.data.items.find(font => font.family === 'Inter');

						const daysOne = resp.data.items.find(font => font.family === 'Days One');

						const firstFifty = resp.data.items.slice(0, 50);

						const isBebas = firstFifty.find(font => font.family === 'Bebas Neue');
						if (!isBebas) {
							firstFifty.push(bebasNeue);
						}

						const isInter = firstFifty.find(font => font.family === 'Inter');
						if (!isInter) {
							firstFifty.push(inter);
						}

						const isDaysOne = firstFifty.find(font => font.family === 'Days One');
						if (!isDaysOne) {
							firstFifty.push({ family: 'Days One' });
						}

						const sorted = firstFifty.sort((a, b) => (a.family > b.family ? 1 : -1));

						dispatch('loadFontUrls', sorted);

						commit('SET_FONTS', sorted);
						resolve(sorted);
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		loadFontUrls({ commit }, fonts) {
			fonts.forEach(font => {
				const head = document.head;
				const link = document.createElement('link');

				link.rel = 'stylesheet';
				link.href = `https://fonts.googleapis.com/css2?family=${font.family}:wght@300;400;500;600;700&display=swap`;

				// Check if the font is already loaded before appending the link
				const existingLink = document.querySelector(
					`link[href^="https://fonts.googleapis.com/css2?family=${font.family}:wght@300;400;500;600;700"]`,
				);
				if (!existingLink) {
					head.appendChild(link);
				}
			});
		},

		loadSingleFont({ commit }, fontFamily) {
			const head = document.head;
			const link = document.createElement('link');

			link.rel = 'stylesheet';
			link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700&display=swap`;

			// Check if the font is already loaded before appending the link
			const existingLink = document.querySelector(
				`link[href^="https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700"]`,
			);
			if (!existingLink) {
				head.appendChild(link);
			}
		},
	},
};
