/**
 * Session initialization.
 */
import store from '@/store';
import axios from '@axios';
import { setProperties } from '@/utils/mixpanel';

const initStreamer = function () {
	const at = localStorage.getItem('accessToken');
	if (at && at.length > 0) {
		axios
			.get(`/init/streamer`)
			.then(res => {
				store.commit('app/SET_MESSAGES', res.data.messages);
				store.commit('app/SET_ANALYTICS', res.data.analytics);
				store.commit('app/SET_ACTIVATED_EXTENSIONS', res.data.activatedExtensions);

				setProperties();
			})
			.catch(error => console.log(error));
	}
};

export { initStreamer };
