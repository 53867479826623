<template>
	<GenericPopup :modal="modal" :maxWidth="700" @close="$emit('close')">
		<template #primaryHeader>An A.I Voice Has Been Shared!</template>
		<template>
			<v-row class="mt-3 mb-5" justify="center">
				<v-col cols="12" sm="5">
					<div class="d-flex flex-column align-center justify-center mb-4">
						<div class="d-flex align-center">
							<img src="@/assets/icons/AIShared.png" class="mr-1" />
							<h4 class="fs--18 fw--300 text-center mb-3 mt-2">StreamStickers</h4>
						</div>

						<v-avatar size="80" class="avatar-stroke mb-3">
							<v-img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"></v-img>
						</v-avatar>
					</div>
					<div class="voice mb-5">
						<h4 class="fs--15 fw--100 text-left mb-3">Voice Preview</h4>
						<MusicPlayer v-if="voice" :audio="audioUrl" :id="'voice-preview-' + voice.id" />
						<MusicPlayer
							v-else
							audio="https://actions.google.com/sounds/v1/human_voices/man_laugh_and_knee_slap.ogg"
							id="shared-voice-preview" />
					</div>
				</v-col>

				<v-col cols="12" sm="1" class="text-center">
					<v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
					<v-divider v-else vertical></v-divider>
				</v-col>

				<v-col cols="12" sm="5" class="d-flex flex-column align-center pa-0">
					<h4 class="fs--18 fw--300 text-center mb-3 mt-2">Link Voice to a TTS Sticker</h4>
					<p class="fs--12 fw--100 mt-2 mb-8 offWhite--text">
						This voice will now appear in your list of A.I voices as "StreamStickers".
					</p>

					<v-btn @click="actions.gotoStickers" fab color="pink" width="100" height="100">
						<v-icon size="38">{{ icons.plus }}</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>
	</GenericPopup>
</template>

<script>
import { computed } from '@vue/composition-api';
import router from '@/router';
import GenericPopup from '@/components/GenericPopup';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import { getVoicePreviewUrl } from '@/utils/tts-utils';
import { mdiPlus } from '@mdi/js';
export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
		voice: {
			type: Object,
			default: null,
		},
	},
	components: {
		GenericPopup,
		MusicPlayer,
	},
	setup(props, ctx) {
		const audioUrl = computed(() => {
			return '';
			// return getVoicePreviewUrl(props.voice.id, props.voice.enableAccentEnhancement);
		});

		const actions = {
			gotoStickers() {
				router.push('/stickers-tts?tab=custom-ai-tts');
			},
		};

		return {
			audioUrl,
			actions,
			icons: {
				plus: mdiPlus,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.avatar-stroke {
	border: 2px solid #53ffff;
}
</style>
