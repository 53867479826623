/**
 * Load the promo module based on the current date or a default promo.
 */
import promoDefault from './global-tts and-follow-on-x.js';
import promoObs from './obsbot.js';

let promo = promoDefault;

// OBSBOT promo.
const now = new Date().getTime();
const obsStart = new Date('2024-09-09T00:00:00-04:00').getTime();
const obsEnd = new Date('2024-09-29T23:59:59-04:00').getTime();
if (now >= obsStart && now <= obsEnd) {
	promo = promoObs;
}

export default promo;
