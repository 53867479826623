<template>
	<div>
		<v-card :flat="tutorial">
			<v-card-text>
				<h3 v-if="!tutorial" class="fs--22 fw--400">Test Your Setup</h3>
				<v-row class="mt-3">
					<v-col cols="12" sm="5">
						<v-btn @click="actions.showTestSticker" :disabled="!isExtensionOnline" color="pink" block
							min-height="61" class="mb-4">TEST STICKER</v-btn>

						<p class="fieldFore--text fs--12 fw--400 mb-0">
							Once you have added the Browser Source to your streaming software, use the
							<span class="pink--text">TEST STICKER</span> button to test your integration. If configured
							correctly, you should both <span class="pink--text">see</span> and
							<span class="pink--text">hear</span> the sticker. Note: Your stream does not have to be live
							to test the sticker. If the button above appears greyed out, it means your Browser Source is
							not yet active in your streaming software. Please confirm it is active and visible in all
							scenes.
						</p>

						<a v-if="!tutorial" class="fieldFore--text fs--12" href=" https://discord.com/invite/2ePbGrc"
							target="_blank">Not working? Get help here!</a>
					</v-col>

					<v-col cols="12" sm="1" class="text-center">
						<v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
						<v-divider v-else vertical></v-divider>
					</v-col>

					<v-col cols="12" sm="5">
						<v-btn @click="actions.toggleTestSize" :disabled="!isExtensionOnline" color="pink"
							min-height="61" block class="mb-4"><span v-if="!sizeToggle">TEST BROWSER<br />SOURCE
								SIZE</span><span v-if="sizeToggle">TURN OFF BROWSER<br />SOURCE SIZE TEST</span></v-btn>
						<p class="fieldFore--text fs--12 fw--400 mb-0">
							If you manually added the Browser Source (rather than using the Drag & Drop feature for
							OBS), make sure it is correctly sized to cover the entire stream. Click the <span class="pink--text">TEST BROWSER
							SOURCE SIZE</span> button above to check. If the Browser Source isn’t fully covering the stream
							window, open its properties in your streaming software and adjust the height and width
							values to match your stream's output resolution. Ensure that all four purple triangles align
							precisely with the corners of your stream window.
							<!-- Next, open the Browser Source Properties in your streaming software and adjust the
							<span class="pink--text">height</span> and <span class="pink--text">width</span> values to
							match your stream output resolution. When done, use the
							<span class="pink--text">TEST BROWSER SOURCE SIZE</span> button to confirm it's sized and
							aligned properly. Ensure that the four purple triangles line up with the corners of your
							stream window. -->
						</p>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import axios from '@axios';
import { onBeforeUnmount, ref } from '@vue/composition-api';
import { getExtensionStatus } from '../../../utils/sticker';
import { MessageEnum } from '../../../utils/constants';

export default {
	props: {
		tutorial: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const sizeToggle = ref(false);

		// Get extension status on initial load.
		const isExtensionOnline = ref(false);
		getExtensionStatus(data => {
			isExtensionOnline.value = data.online;
		});

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.EXTENSION_STATUS) {
				isExtensionOnline.value = payload.value;
			}
		});

		// Stop event listener.
		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		const actions = {
			showTestSticker: () => {
				axios.post('/test-sticker');
			},
			toggleTestSize: () => {
				sizeToggle.value = !sizeToggle.value;
				axios.post(`/test-size/${sizeToggle.value}`);
			},
		};

		return {
			actions,
			isExtensionOnline,
			sizeToggle,
		};
	},
};
</script>
