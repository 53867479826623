import Vue from 'vue';
import VueRouter from 'vue-router';
import jwt from 'jsonwebtoken';
import moment from 'moment';

import { GTAG_KEY } from '@/utils/constants';
import { trackPageView } from '@/utils/mixpanel';

Vue.use(VueRouter);

// TODO Check JWS on all routes, except for login.
// See Materio docs - maybe they are already doing this?
const routes = [
	{
		path: '/',
		redirect: to => {
			// Read JWS from the hash if we are returning from login and write to local storage.
			if (to.hash && to.hash.length > 0 && to.hash.startsWith('#jws=')) {
				const jws = to.hash.substring(to.hash.indexOf('=') + 1, to.hash.length);
				localStorage.setItem('accessToken', jws);
			}

			// Read from local storage and send to home or login if no JWS present or if
			// an existing JWS has expired.
			// TODO Move this to a plugin - piggyback on the Materio "acl" plugin?
			let goToLogin = true;
			if (localStorage.getItem('accessToken')) {
				try {
					const jwtClaims = jwt.decode(localStorage.getItem('accessToken'));
					const now = moment();
					const expires = moment(jwtClaims.exp * 1000);
					if (expires.isAfter(now)) {
						goToLogin = false;
					}
				} catch (error) {
					console.error(error);
					localStorage.removeItem('accessToken');
				}
			}

			if (goToLogin) {
				return { name: 'auth-login', hash: '' };
			} else {
				return { name: 'home', hash: '' };
			}
		},
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			layout: 'content',
			page: 'Home',
		},
	},
	{
		path: '/login',
		name: 'auth-login',
		component: () => import('@/views/LoginPage.vue'),
		meta: {
			layout: 'blank',
			page: 'Login',
		},
	},
	{
		path: '/stickers/:tab?',
		name: 'streamer-stickers',
		component: () => import('@/views/streamer/Stickers.vue'),
		meta: {
			layout: 'content',
			page: 'Stickers',
		},
	},
	{
		path: '/stickers-tts/:tab?',
		name: 'streamer-stickers-tts',
		component: () => import('@/views/streamer/TTSStickers.vue'),
		meta: {
			layout: 'content',
			page: 'Stickers',
		},
	},
	{
		path: '/stick-o-vision',
		name: 'stick-o-vision',
		component: () => import('@/views/streamer/StickOVision.vue'),
		meta: {
			layout: 'content',
			page: 'Stick O Vision',
		},
	},
	{
		path: '/insights',
		name: 'streamer-insights',
		component: () => import('@/views/streamer/Insights.vue'),
		meta: {
			layout: 'content',
			page: 'Inisghts',
		},
	},
	{
		path: '/sticker-party',
		name: 'streamer-sticker-party',
		component: () => import('@/views/streamer/StickerParty.vue'),
		meta: {
			layout: 'content',
			page: 'Sticker Party',
		},
	},
	{
		path: '/tts-voice-lab',
		name: 'streamer-tts-voice-lab',
		component: () => import('@/views/streamer/TTSVoiceLab.vue'),
		meta: {
			layout: 'content',
			page: 'TTS Voice Lab',
		},
	},
	{
		path: '/profile',
		name: 'streamer-profile',
		component: () => import('@/views/streamer/Profile.vue'),
		meta: {
			layout: 'content',
			page: 'Profile',
		},
	},
	{
		path: '/chat-bot',
		name: 'streamer-chat-bot',
		component: () => import('@/views/streamer/ChatBot.vue'),
		meta: {
			layout: 'content',
			page: 'Chat Bot',
		},
	},
	{
		path: '/settings',
		name: 'streamer-settings',
		component: () => import('@/views/streamer/Settings.vue'),
		meta: {
			layout: 'content',
			page: 'Settings',
		},
	},
	// {
	// 	path: '/subscriptions',
	// 	name: 'subscriptions',
	// 	component: () => import('@/views/Subscriptions.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	{
		path: '/activity-dashboard',
		name: 'activity-dashboard',
		component: () => import('@/views/ActivityDashboard.vue'),
		meta: {
			layout: 'blank',
		},
	},
	{
		path: '*',
		redirect: 'error-404',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

router.beforeEach((to, from, next) => {
	// Trigger a page view event for Google Analytics
	if (window.gtag) {
		const host = window.location.host;
		let hostType = 'DEV';

		if (host.startsWith('dashboard.streamstickers.com')) {
			hostType = 'PROD';
		}
		window.gtag('config', GTAG_KEY[hostType], { page_path: to.path });
	}

	next(); // Proceed to the next route
});

// Track page views.
router.afterEach((to, from) => {
	if (to.meta && to.meta.page) {
		try {
			const query = router.currentRoute.query;
			let tab = null;
			if (query && query.tab) {
				tab = query.tab.replace('ai-tts', 'A.I. TTS').replaceAll('-' , ' ');
				
				tab = tab.charAt(0).toUpperCase() + tab.slice(1);
			}

			const pageName = `${ tab ? tab : to.meta.page } ${to.meta.page}`;

			trackPageView(pageName);
		} catch (error) {
			console.error(error);
		}
	}
});

export default router;
