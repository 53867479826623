const promo = {
	key: 'obsbot',
	// cms/src/@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue
	nav: {
		isEnabled: true,
		image: '/banners/obsbot/200x300-OBSBOT.png',
		url: null,
		ga: {
			event_name: 'campaign_banner_click',
			event_category: 'engagement',
			event_label: 'User Clicked the Campaign Banner',
			title: 'OBSBOT',
			unit: '200x300',
		},
	},
	// cms/src/views/streamer/home/promotion/Banners.vue
	main: {
		isEnabled: true,
		image: {
			left: '/banners/obsbot/TopBanner.png',
			right: '/banners/obsbot/BottomBanner.png',
		},
		url: null,
		urlPopup: 'obsbot',
		ga: {
			event_name: 'campaign_banner_click',
			event_category: 'engagement',
			event_label: 'User Clicked the Campaign Banner',
			title: 'OBSBOT',
			unit: '728x90',
		},
		actionCopy: null,
		actionUrl: null,
		actionGa: {
			event_name: 'download_link_click',
			event_category: 'engagement',
			event_label: null,
			title: null,
			unit: null,
		},
	},
};

export default promo;
