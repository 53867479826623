<template>
	<!-- Choose component or panel view. -->
	<div>
		<div v-if="step === Steps.CHOOSE_VIEW_MODE">
			<h4 class="fw--400 text-center">
				Select how you would like the Stream Stickers Extension to appear on your stream
			</h4>
			<v-row no-gutters justify="center" class="mt-4 px-3">
				<v-col cols="12" lg="6" class="text-center px-1">
					<img
						src="@/assets/images/Component.png"
						width="100%"
						alt="Component Mode"
						class="mode cursor-pointer border"
						:class="viewMode === ActivationLocationType.COMPONENT && 'active'"
						@click="actions.setViewMode(ActivationLocationType.COMPONENT)" />

					<h4 class="fw--400 text-center mt-2">Component Mode</h4>
					<p class="fs--13 fw--400 shinyPurple--text">Appears on top of the live stream window</p>
				</v-col>
				<v-col cols="12" lg="6" class="text-center px-1">
					<img
						src="@/assets/images/Panel.png"
						width="100%"
						class="mode cursor-pointer border"
						:class="viewMode === ActivationLocationType.PANEL && 'active'"
						alt="Panel Mode"
						@click="actions.setViewMode(ActivationLocationType.PANEL)" />

					<h4 class="fw--400 text-center mt-2">Panel Mode</h4>
					<p class="fs--13 fw--400 shinyPurple--text">Appears below the live stream window</p>
				</v-col>
			</v-row>

			<div class="footer-section text-center mt-5">
				<v-btn class="my-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
			</div>
		</div>
		<!-- Choose the slot for the extension. -->
		<div v-else-if="step === Steps.CHOOSE_SLOT">
			<p
				v-if="viewMode === ActivationLocationType.COMPONENT"
				class="shinyPurple--text fw--400 text-center px-4 mb-4">
				All your Component Extension Slots are currently occupied. Twitch allows up to 2 Component Extensions to
				be activated at the same time. Please select which Component Extension you would like Stream Stickers to
				replace. If you prefer not to replace any, you can also try activating it as a
				<a @click="actions.moveBack" class="text-link">Panel Extension</a>
				instead if slots are available.
			</p>
			<p
				v-else-if="viewMode === ActivationLocationType.PANEL"
				class="shinyPurple--text fw--400 text-center px-4 mb-4">
				All your Extension Slots are currently occupied. Twitch allows up to 3 Panel Extensions to be activated
				at the same time. Please select which Panel Extension you would like Stream Stickers to replace. If you
				prefer not to replace any, you can also try activating it as a
				<a @click="actions.moveBack" class="text-link">Component Extension</a>
				instead if slots are available.
			</p>

			<v-row
				v-for="ext in activatedExtensionsForViewMode"
				:key="ext.slot"
				class="text-center"
				:class="ext.isStreamStickers ? 'selected-row' : ''">
				<v-col>Extension Slot {{ ext.slot }}</v-col>
				<v-col class="text-left">
					<div v-if="!ext.isActive">Available</div>
					<div v-else-if="ext.isStreamStickers">{{ ext.name }}</div>
					<div v-else>{{ `${ext.name} [${ext.version}]` }}</div>
				</v-col>
				<v-col>
					<div v-if="ext.isStreamStickers">Selected</div>
					<div v-else-if="ext.isActive">
						<v-btn depressed @click="actions.selectSlot(ext.slot)" class="secondary" small>Replace</v-btn>
					</div>
					<div v-else>
						<v-btn depressed @click="actions.selectSlot(ext.slot)" class="secondary" small>Select</v-btn>
					</div>
				</v-col>
			</v-row>

			<div class="footer-section text-center mt-5">
				<v-btn class="my-6 mr-3 px-12" depressed @click="actions.moveBack" color="pink">Back</v-btn>
				<v-btn
					class="my-6 px-12"
					depressed
					:disabled="!selectedExtensionForViewMode"
					@click="actions.moveNext"
					color="pink">
					Next
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api';
import store from '@/store';
import axios from '@axios';
import { initStreamer } from '@/utils/init.js';
import { ActivationLocationType } from './tutorial-constants.js';

export default {
	emits: ['onActivate'],
	setup(props, { emit }) {
		// Load a copy of the activated extensions from the store.
		const loadFromStore = () => {
			if (store.state.app.activatedExtensions) {
				return JSON.parse(JSON.stringify(store.state.app.activatedExtensions));
			}
			return [];
		};

		const activatedExtensions = ref(loadFromStore());

		const Steps = reactive({
			CHOOSE_VIEW_MODE: 'CHOOSE_VIEW_MODE',
			CHOOSE_SLOT: 'CHOOSE_SLOT',
		});

		const step = ref(Steps.CHOOSE_VIEW_MODE);
		const viewMode = ref(ActivationLocationType.COMPONENT);

		onMounted(() => {
			// Pre-select view mode if the extension has already been activated.
			const activatedPanel = findExtensionsByType(ActivationLocationType.PANEL).find(ext => ext.isStreamStickers);

			if (activatedPanel) {
				actions.setViewMode(ActivationLocationType.PANEL);
			} else {
				actions.setViewMode(ActivationLocationType.COMPONENT);
			}
		});

		const findExtensionsByType = type => {
			if (activatedExtensions.value.length) {
				return activatedExtensions.value.find(ext => ext.type === type).extensions;
			}

			return [];
		};

		const activatedExtensionsForViewMode = computed(() => {
			if (activatedExtensions.value.length) {
				return activatedExtensions.value.find(ext => ext.type === viewMode.value).extensions;
			}
			return undefined;
		});

		const selectedExtensionForViewMode = computed(() => {
			if (activatedExtensions.value.length) {
				return activatedExtensionsForViewMode.value.find(ext => ext.isStreamStickers);
			}
			return undefined;
		});

		const actions = {
			// Component or panel view.
			setViewMode: mode => {
				viewMode.value = mode;

				// Reload the activated extensions from the store.
				activatedExtensions.value = loadFromStore();

				// If the extension is not activated, find the first open slot.
				const activatedExtension = findExtensionsByType(viewMode.value).find(ext => ext.isStreamStickers);
				if (!activatedExtension) {
					const openSlot = findExtensionsByType(viewMode.value).find(ext => !ext.isActive);
					if (openSlot) {
						actions.selectSlot(openSlot.slot);
					}
				}
			},
			// Slot selection.
			selectSlot: slot => {
				// Reload the activated extensions from the store.
				activatedExtensions.value = loadFromStore();

				// Clear the selected slot.
				activatedExtensions.value.forEach(e => {
					e.extensions.forEach(e => {
						if (e.isStreamStickers) {
							e.isStreamStickers = false;
							e.isActive = false;
							e.name = null;
							e.id = null;
							e.version = null;
						}
					});
				});

				const extSlot = activatedExtensionsForViewMode.value.find(ext => ext.slot === slot);
				if (extSlot) {
					extSlot.isStreamStickers = true;
					extSlot.isActive = true;
					extSlot.name = 'Stream Stickers';
					extSlot.id = null;
					extSlot.version = null;
				}
			},
			moveNext: () => {
				const emitOnActivate = () => {
					emit('onActivate', viewMode.value);
				};

				// If the extension cannot be auto-assigned to a slot (no available slots), show the choose slot UI.
				if (step.value == Steps.CHOOSE_VIEW_MODE) {
					if (!selectedExtensionForViewMode.value) {
						step.value = Steps.CHOOSE_SLOT;
						return;
					}
				}

				if (selectedExtensionForViewMode.value) {
					axios
						.put(`/extension-activate/${viewMode.value}/${selectedExtensionForViewMode.value.slot}`)
						.then(() => {
							// Reload store after activation.
							initStreamer();
							emit('onActivate', viewMode.value);
						});
				}
			},
			moveBack: () => {
				step.value = Steps.CHOOSE_VIEW_MODE;
			},
		};

		return {
			Steps,
			step,
			viewMode,
			actions,
			activatedExtensionsForViewMode,
			ActivationLocationType,
			selectedExtensionForViewMode,
		};
	},
};
</script>

<style scoped>
.border {
	border: 2px solid transparent !important;
}

.active {
	border: 2px solid var(--v-pink-base) !important;
}

.selected-row {
	color: #66d544;
}

.text-link {
	color: var(--v-pink-base);
	cursor: pointer;
}
</style>
