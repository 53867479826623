<template>
	<div>
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
		<template v-if="!media">
			<v-row>
				<v-col cols="12">
					<v-card flat>
						<v-card-text class="py-3">
							<h3 class="fs--16 text-center fw--400">
								Choose your streaming software to implement the Stream Stickers browser source.
							</h3>
							<span class="fieldFore--text fs--12 fw--400"> </span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" class="mb-5">
					<v-card height="100%" flat>
						<v-card-text class="text-center">
							<div class="mt-5">
								<img src="@/assets/icons/source/obspng.png" width="90" alt="OBS" />

								<v-btn
									block
									depressed
									rounded
									color="pink"
									class="text-capitalize mt-5"
									@click="media = 1"
									>I use OBS Studio</v-btn
								>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" class="mb-5">
					<v-card flat height="100%">
						<v-card-text>
							<div class="d-flex flex-wrap align-center justify-center mt-5">
								<img src="@/assets/icons/source/slpng.png" width="95" alt="SL" />
								<img src="@/assets/icons/source/splitpng2.png" width="95" alt="Split" />
								<img src="@/assets/icons/source/LightStream.png" width="95" alt="LightStream" />
							</div>

							<v-btn
								block
								depressed
								outlined
								rounded
								color="pink"
								class="text-capitalize mt-5"
								@click="media = 2"
								>I use something else</v-btn
							>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</template>

		<template v-if="media === 1">
			<v-card flat>
				<v-card-text class="text-center">
					<h3 class="fs--18 fw--400 mb-2">Drag and Drop the button below in your OBS starting scene.</h3>
					<draggable>
						<v-btn
							:href="browserSourceLink"
							rounded
							depressed
							color="pink"
							class="text-capitalize drag-source"
							>Drag &amp; Drop this button into OBS</v-btn
						>
					</draggable>
				</v-card-text>
			</v-card>
		</template>

		<template v-if="media === 2">
			<h4 class="fw--400 text-center">Add the following browser source to your streaming software.</h4>
			<v-row no-gutters justify="center" class="mt-4 px-3">
				<v-col cols="9" sm="9" lg="6" class="pr-3">
					<v-text-field
						v-model="browserSourceLink"
						disabled
						background-color="purple"
						color="fieldFore"
						dense
						flat
						solo
						hide-details></v-text-field>
				</v-col>
				<v-col cols="3" sm="3" lg="2">
					<v-btn depressed color="primary" block @click="actions.copy">Copy</v-btn>
				</v-col>
			</v-row>
		</template>

		<template v-if="media">
			<div class="mt-2 px-8">
				<span class="white--text fs--12 fw--400">
					<strong>Important:</strong> After adding the above Browser Source to your starting scene, copy and paste it into your other scenes in OBS instead of creating a new Browser Source for each scene. This will prevent audio duplication.
				</span>
				<a class="fs--12 pink--text" href="https://discord.com/invite/2ePbGrc" target="_blank">Need Help?</a>
			</div>
		</template>

		<div class="footer-section text-center mt-5">
			<v-btn depressed color="primary" class="my-6" @click="actions.moveBack"
				><v-icon>{{ iconLeft }}</v-icon> Back</v-btn
			>
			<v-btn v-if="media" class="my-6 px-12 ml-3" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { getEndpointHost } from '../../utils/config';
import { getUserId } from '../../utils/auth';
import { mdiChevronLeft } from '@mdi/js';

export default {
	setup(props, { emit }) {
		const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
		const copied = ref(false);
		const media = ref(null);

		const actions = {
			moveBack: () => {
				emit('move', 1);
			},
			moveNext: () => {
				emit('move', 3);
			},

			// Copy the OBS URL to the clipboard.
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
		};

		return {
			media,
			actions,
			browserSourceLink,
			copied,
			iconLeft: mdiChevronLeft,
		};
	},
};
</script>
